@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url("./fonts/poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Thin";
  src: local("Poppins"), url("./fonts/poppins/Poppins-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Just Me Again Down Here";
  src: local("Just Me Again Down Here"), url("./fonts/JustMeAgainDownHere-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Kolker Brush";
  src: local("Kolker Brush"), url("./fonts/KolkerBrush-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "La Belle Aurore";
  src: local("La Belle Aurore"), url("./fonts/LaBelleAurore-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Morguns";
  src: local("Morguns"), url("./fonts/Morguns-Condensed.ttf") format("truetype");
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  border: 8px solid transparent;
  border-radius: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: "transparent";
  width: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  width: 10px !important;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}


.chakra-radio__control {
  border-color: #323D61 !important;
}
.chakra-radio__control[data-checked] {
  background: unset !important;
  background-color: black !important;
}
.chakra-radio__control[data-checked].radio-in-negotiation {
  background-color: #F6C547 !important;
}

.chakra-radio__control[data-checked].radio-in-negotiation {
  background-color: #F6C547 !important;
}

.chakra-switch__thumb {
 background:  #7BFF66 !important;
}

body {
  background-color: black !important
}

option {
  background: white !important;
  color: #323D61 !important;
}
select {
  color: #323D61 !important;
}

/* This should be the date input */
[name*="date"][aria-haspopup="dialog"]:not(.negotiating-date-input) {
  border-color: black !important;
  color: black !important
}

[name*="date"][aria-haspopup="dialog"].negotiating-date-input {
  border-color: white !important;
  color: white !important
}